import React, { useState } from "react"
import { useProgress } from "@react-three/drei"
import { cn } from "../lib/cn"

const LoadingScreen = () => {
  const [loading, setLoading] = useState(true)
  const { progress } = useProgress()

  React.useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [progress])

  return (
    <div
      className={cn("fixed flex items-center justify-center top-0 left-0 transition-all duration-700 w-full h-screen bg-black z-50", {
        "opacity-0 pointer-events-none": !loading
      })}>
      <div className="absolute animate-bounce duration-600">
        <img src="/logo.svg" alt="Gian's logo" className="w-auto h-20 invert" />
      </div>
      <div style={{ width: "300px" }} className="relative mt-40">
        <div className="h-3 bg-gray-500 rounded-full overflow-hidden">
          <div style={{ width: `${progress}%` }} className="h-full bg-white transition-all duration-700" />
        </div>
        <div className="text-white text-center mt-4">{progress === 100 ? "Ingresando..." : `${Math.floor(progress)}%`}</div>
      </div>
    </div>
  )
}

export default LoadingScreen
