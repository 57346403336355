import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import { cn } from "../lib/cn"
import { HiChevronLeft, HiChevronRight } from "react-icons/hi"

const Work = ({ title, note, slides }) => {
  const swiperRef = React.useRef(null)
  const [expandedImage, setExpandedImage] = React.useState(null)
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  // Listen for ESC key to close the expanded image
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setExpandedImage(null)
      }
    }

    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  const isExpandedVideo = expandedImage?.includes(".mp4")

  return (
    <>
      <div style={{ height: "100vh" }}>
        <div className="dot flex flex-col w-full px-5 md:px-10 lg:px-20 z-20 pt-40">
          <div className="flex w-full flex-col md:flex-row md:justify-between  items-end md:items-center">
            <div className="flex w-full flex-col md:flex-row gap-2 md:items-center">
              <h1 className="uppercase text-3xl md:text-5xl leading-thight text-black dark:text-white font-normal">{title}</h1>
              <p className="text-lg uppercase text-left text-black dark:text-white">- {note}</p>
            </div>
            <div className="flex gap-2 items-center">
              <button
                className="h-10 w-10 flex items-center justify-center bg-black dark:bg-white text-white dark:text-black rounded-full disabled:opacity-50"
                onClick={() => swiperRef.current.swiper.slidePrev()}>
                <HiChevronLeft className="text-xl" />
              </button>
              <button
                className="h-10 w-10 flex items-center justify-center bg-black dark:bg-white text-white dark:text-black rounded-full disabled:opacity-50"
                onClick={() => swiperRef.current.swiper.slideNext()}>
                <HiChevronRight className="text-xl" />
              </button>
            </div>
          </div>

          <div className="w-full relative">
            <Swiper
              slidesPerView={windowWidth > 1024 ? 2.5 : windowWidth > 768 ? 2.25 : 1}
              spaceBetween={windowWidth > 1024 ? 50 : windowWidth > 768 ? 30 : 10}
              className="mt-12"
              ref={swiperRef}>
              {slides.map((card, index) => (
                <SwiperSlide key={index}>
                  <WorkCard title={card.title} description={card.text} src={card.src} imageMock={card.imageMock} imageAlt={card.imageAlt} setExpandedImage={setExpandedImage} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div
        className={cn("fixed flex transition-all duration-500 items-center justify-center w-full h-screen  z-50 top-0 left-0", {
          "opacity-0 pointer-events-none": !expandedImage
        })}>
        {!isExpandedVideo && (
          <img
            src={expandedImage}
            alt=""
            className={cn("h-full max-h-[80vh] z-10 w-auto object-cover translate-y-2 delay-300 duration-500 transition-all opacity-0", {
              "translate-y-0 opacity-100": expandedImage
            })}
          />
        )}
        {isExpandedVideo && (
          <video
            src={expandedImage}
            autoPlay
            loop
            controls
            muted
            className={cn("h-full max-h-[80vh] z-10 w-auto object-cover translate-y-2 delay-300 duration-500 transition-all opacity-0", {
              "translate-y-0 opacity-100": expandedImage
            })}
          />
        )}
        <div onClick={() => setExpandedImage(null)} className="absolute top-0 right-0 w-full h-full bg-black bg-opacity-30 delay-300" />
      </div>
    </>
  )
}

export default Work

const WorkCard = ({ title, description, src, imageAlt, imageMock, setExpandedImage }) => {
  const hqImage = src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1")
  const isVideo = src?.includes(".mp4")

  return (
    <div className="flex w-full flex-col shrink-0 text-center gap-2 backdrop-blur-lg p-5 overflow-hidden">
      <h1 className="text-xl md:text-3xl uppercase dark:text-white text-black font-semibold">{title}</h1>
      {description && <p className="uppercase  text-black dark:text-gray-300 mb-4">{description}</p>}
      {imageMock === "desktop" && <DesktopMock src={src} setExpandedImage={setExpandedImage} />}
      {imageMock === "phone" && <MobileMock src={src} setExpandedImage={setExpandedImage} />}
      {imageMock === "none" && !isVideo && <img src={src} alt="" className="h-52 w-fit mx-auto" onClick={() => setExpandedImage(hqImage)} />}
      {imageMock === "none" && isVideo && (
        <div className="h-52 w-fit mx-auto" onClick={() => setExpandedImage(src)}>
          <video src={src} autoPlay loop muted className="w-full h-full object-cover" />
        </div>
      )}
      {imageAlt && <p className="uppercase text-black dark:text-gray-300 mt-2">{imageAlt}</p>}
    </div>
  )
}

const DesktopMock = ({ src, setExpandedImage }) => {
  const isVideo = src?.includes(".mp4")
  // add _hq before the last "." to the image name to get the high quality image
  const hqImage = src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1")

  return (
    <div className="relative flex w-full h-52 justify-center" onClick={() => setExpandedImage(isVideo ? src : hqImage)}>
      <img src="/images/mocks/macbook.svg" alt="" className="h-52 absolute z-20" />
      {!isVideo && <img src={src} alt="" className="h-[150px] absolute z-10" />}
      {isVideo && (
        <div className="h-[150px] top-1 w-[235px] overflow-hidden absolute z-10  rounded-lg">
          <video src={src} autoPlay loop muted className="w-full h-full object-cover" />{" "}
        </div>
      )}
    </div>
  )
}

const MobileMock = ({ src, setExpandedImage }) => {
  const isVideo = src?.includes(".mp4")

  const hqImage = src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1")

  return (
    <div className="relative flex w-full h-52 justify-center" onClick={() => setExpandedImage(isVideo ? src : hqImage)}>
      <img src="/images/mocks/iphone.svg" alt="" className="h-52 absolute z-20" />
      {isVideo && <video src={src} autoPlay loop muted className="w-[6rem] h-[13rem] object-cover  rounded-xl" />}

      {!isVideo && (
        <div alt="" className="h-[12.5rem] top-1 w-24 absolute z-10">
          <img src={src} alt="" className="w-full h-full object-cover  rounded-xl" />
        </div>
      )}
    </div>
  )
}
