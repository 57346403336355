import React from "react"
import Button from "./Button"
import { Sections } from "../config/sections"
import DarkModeSwitch from "./DarkModeSwitch"

export const NAV_ITEMS = [
  {
    name: "Home",
    id: Sections.HOME,
    offset: 0,
    percent: 0
  },
  {
    name: "My Work",
    id: Sections.MY_WORK,
    offset: 800,
    percent: 0.12
  },
  {
    name: "About Me",
    id: Sections.ABOUT_ME,
    offset: 4600,
    percent: 0.7
  },
  {
    name: "Contact",
    id: Sections.CONTACT,
    offset: 6500,
    percent: 0.95
  }
]

const Header = ({ currentSection, setCurrentSection }) => {
  const handleScroll = (id) => {
    const scrollDiv = document.getElementById("scroll-area")
    const scrollOffset = NAV_ITEMS.find((item) => item.id === id).offset
    scrollDiv.scrollTo({
      top: scrollOffset,
      behavior: "smooth"
    })
  }

  return (
    <div className="fixed z-50 top-0 right-0 w-full h-20 px-5 md:px-10 lg:px-20 items-center justify-between flex">
      <img src="/logo.svg" className="w-16 blend-me h-auto dark:invert" />
      <div className="flex gap-10 items-center">
        <div className="md:flex items-center gap-4 hidden">
          {NAV_ITEMS.map((item) => (
            <Button key={item.id} onClick={() => handleScroll(item.id)} upperCase active={currentSection === item.id}>
              {item.name}
            </Button>
          ))}
        </div>
        <DarkModeSwitch />
      </div>
    </div>
  )
}

export default Header
