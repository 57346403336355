import React, { useState } from "react"
import useDarkMode from "../hooks/useDarkMode"
import { DarkModeSwitch } from "react-toggle-dark-mode"

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkMode()
  const [darkMode, setDarkMode] = useState(colorTheme === "light" ? true : false)

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme)
    setDarkMode(checked)
  }

  return (
    <>
      <div>
        <DarkModeSwitch checked={darkMode} onChange={toggleDarkMode} size={24} />
      </div>
    </>
  )
}
