import React from "react"

const Home = (props) => {
  return (
    <div style={{ height: "100vh" }}>
      <div className="dot px-5 md:px-10 lg:px-20 pt-32 md:pt-40">
        <h1 className="uppercase text-5xl md:text-[80px] leading-none text-black dark:text-white font-medium">Gianluca</h1>
        <h1 className="uppercase text-5xl md:text-[80px] leading-tight  text-black dark:text-white font-medium">Nicolicchia</h1>
        <div className="relative flex w-fit flex-col items-end">
          <h2 className="uppercase text-xl md:text-3xl text-black dark:text-white">Multimedia</h2>
          <h2 className="uppercase text-xl md:text-3xl text-black dark:text-white">Design</h2>
          <div className="absolute -right-10 top-2">
            <svg className="dark:invert-0 invert" width="30" height="60" viewBox="0 0 30 94" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g style={{ mixBlendMode: "difference" }}>
                <path
                  d="M17 2C17 0.89543 16.1046 -4.82823e-08 15 0C13.8954 4.82823e-08 13 0.895431 13 2L17 2ZM13.5858 93.4142C14.3668 94.1953 15.6332 94.1953 16.4142 93.4142L29.1421 80.6863C29.9232 79.9052 29.9232 78.6389 29.1421 77.8579C28.3611 77.0768 27.0948 77.0768 26.3137 77.8579L15 89.1716L3.6863 77.8579C2.90525 77.0768 1.63892 77.0768 0.857868 77.8579C0.076819 78.6389 0.076819 79.9052 0.857868 80.6863L13.5858 93.4142ZM13 2L13 92L17 92L17 2L13 2Z"
                  fill="#FAFAFA"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
