import React, { forwardRef } from "react"
import Header, { NAV_ITEMS } from "./components/Header"
import Home from "./screens/Home"
import MyWork from "./screens/MyWork"
import Work from "./screens/Work"
import AboutMe from "./screens/AboutMe"
import { cn } from "./lib/cn"
import { Sections } from "./config/sections"
import WORKS from "./lib/works"

export const SectionsTrigger = {
  HOME: NAV_ITEMS[0].percent,
  MY_WORK: NAV_ITEMS[1].percent,
  ABOUT_ME: NAV_ITEMS[2].percent,
  CONTACT: NAV_ITEMS[3].percent
}

const Overlay = forwardRef(({ caption, scroll }, ref) => {
  const [currentSection, setCurrentSection] = React.useState(Sections.HOME)
  const [scrollPercent, setScrollPercent] = React.useState(0)

  React.useEffect(() => {
    if (scrollPercent < SectionsTrigger.MY_WORK) {
      setCurrentSection(Sections.HOME)
    }
    if (scrollPercent >= SectionsTrigger.MY_WORK && scrollPercent < SectionsTrigger.ABOUT_ME) {
      setCurrentSection(Sections.MY_WORK)
    }
    if (scrollPercent >= SectionsTrigger.ABOUT_ME && scrollPercent < SectionsTrigger.CONTACT) {
      setCurrentSection(Sections.ABOUT_ME)
    }
    if (scrollPercent >= SectionsTrigger.CONTACT) {
      setCurrentSection(Sections.CONTACT)
    }
  }, [scrollPercent])

  const onSectionChange = (section) => {
    if (section === Sections.HOME) {
      scroll.current = 0
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return (
    <div
      ref={ref}
      onScroll={(e) => {
        scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
        setScrollPercent(scroll.current.toFixed(2))
      }}
      className="scroll z-20"
      id="scroll-area">
      <Header currentSection={currentSection} setCurrentSection={onSectionChange} />
      <Home />
      <MyWork />
      {WORKS.map((work, index) => (
        <Work key={index} title={work.title} note={work.note} slides={work.slides} />
      ))}
      <AboutMe />

      <span className="caption hidden" ref={caption}>
        {scrollPercent}
      </span>
      <div
        className={cn(
          "fixed w-full h-20 z-50 bottom-0 text-black dark:text-white backdrop-blur-xl flex flex-col md:flex-row items-center justify-center transition-all opacity-0 translate-y-8",
          {
            "opacity-100 translate-y-0": scrollPercent > 0.95
          }
        )}>
        <div className="span">
          Made with <span className="mx-1">❤</span> by
        </div>
        <span className="md:ml-2">Gianluca Nicolicchia & Joaquin Santarcangelo</span>
      </div>
    </div>
  )
})

export default Overlay
