import React from "react"
import { cn } from "../lib/cn"

const Button = ({ active, children, className, upperCase, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "text-white dark:text-black rounded-full px-4 py-2 dark:bg-white bg-black outline transition-all duration-300 outline-2 dark:outline-white outline-black",
        {
          "bg-transparent dark:bg-transparent backdrop-blur-sm text-black dark:text-white": !active,
          uppercase: upperCase
        },
        className
      )}>
      {children}
    </button>
  )
}

export default Button
