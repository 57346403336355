import React, { Suspense, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import Model from "./Model"
import Overlay from "./Overlay"
import DotBackground from "./components/DotBackground"
import { Environment } from "@react-three/drei"
import LoadingScreen from "./screens/LoadingScreen"

export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)

  React.useEffect(() => {
    console.log(scroll)
  }, [scroll])
  return (
    <>
      <Canvas className="bg-transparent z-10" shadows eventSource={document.getElementById("root")} eventPrefix="client">
        <ambientLight intensity={0.1} />
        <Suspense fallback={null}>
          <Model scroll={scroll} />
          <Environment path={`${window.location.origin}/`} files="machine_shop.hdr" />
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
      <DotBackground />
      <LoadingScreen />
    </>
  )
}
